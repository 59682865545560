<template>
    <BaseSection class="location-slider" :width="props.width">
        <template
            v-if="title"
            #title
        >
            {{ title }}
        </template>

        <BaseSlider :items="items" v-bind="$attrs" class="card-slider__slider" track-intersection>
            <!-- Pass down whatever slots are given to this component-->
            <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
                <slot :name="slot" v-bind="scope || {}" />
            </template>

            <template #slide="{ item, isVisible }">
                <LocationCard
                    :location-entry="item"
                    :is-visible="isVisible"
                    drag-to-scroll="ignore"
                    draggable="false"
                />
            </template>
        </BaseSlider>
    </BaseSection>
</template>
<script setup>
import { isEmpty } from 'lodash-es';
import { useLocationsQuery } from '~/composables/villus/locations/query';

const props = defineProps({
    title: {
        type: String,
        default: null
    },
    // Should this prop ever change, please update TheMenuOverlaySlider accordingly
    locationEntries: {
        type: Array,
        required: true,
        default: () => []
    },
    width: {
        type: String,
        default: 'bleed'
    },
});

const { data } = await useLocationsQuery();

const items = computed(() => {
    return isEmpty(props.locationEntries) ? data.value.data.entries : props.locationEntries;
});

</script>

<style lang="less">
.location-slider {
    position: relative;
    mask-image: linear-gradient(90deg, rgb(255, 255, 255) 96%, rgba(0, 0, 0, 0) 99.5%);

    .slider {
        --slider-gap: .75rem;

        @media @q-md-min {
            --slider-gap: 1rem;
        }
    }

    .slider__track {
        padding-top: .75rem;
        white-space: unset;

        @media @q-md-min {
            margin-top: -1rem;
            padding-top: 2rem;
        }
    }

    .slider__item:nth-last-child(2) {
        @media @q-md-max {
            margin-right: 1.5rem;
        }
    }

    .section__title {
        .typography-h1();
    }
}
</style>
