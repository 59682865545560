<template>
    <NuxtLink
        v-if="locationEntry"
        :to="localePath(`/${locationEntry.uri}`)"
        class="location-card"
        @mouseover="startFlipper"
        @mouseleave="stopFlipper"
    >
        <div class="location-card__header">
            <div class="location-card__logo">
                <LocationLogo :location="locationEntry.location" :compact="false" color="blue" />
            </div>
            <h4 class="location-card__title">
                {{ locationEntry.textPlain || locationEntry.title }}
            </h4>
        </div>

        <BaseImage
            class="location-card__image base-image--cover"
            :src="image.w320"
            :srcset="`
                ${image.w320} 320w,
                ${image.w768} 768w,
                ${image.w980} 980w,
                ${image.w1260} 1260w
            `"
            sizes="
                (max-width: 380px) 320px,
                768px
            "
            :height="image.height"
            :width="image.width"
            alt=""
        />
    </NuxtLink>
</template>

<script setup>
const props = defineProps({
    locationEntry: {
        type: Object,
        required: true,
        default: null
    },
    isVisible: {
        type: Boolean,
        required: false,
        default: false,
    }
});

const localePath = useLocalePath();

const { image, start: startFlipper, stop: stopFlipper } = useFlipper(props.locationEntry.images, { interval: 800 });

watch(props.locationEntry, (currentValue) => {
    if (window?.innerWidth < 768) {
        currentValue.isVisible ? startFlipper() : stopFlipper();
    } else {
        stopFlipper();
    }
});

onUnmounted(() => {
    stopFlipper();
});
</script>

<style lang="less" src="./LocationCard.less"></style>
